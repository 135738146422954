<template>
	<table-management tableName="Postes" :headers="headers" :items="items" sortby="id" :defaultItem="defaultItem" @onUpdate="onUpdate" @onCreate="onCreate"></table-management>
</template>

<script>
import TableManagement from "../components/TableManagement.vue"
export default {
	name:"Postes",
	data () {
		return { 
			headers: [
				{
					text: 'Id',
					align: 'start',
					sortable: false,
					value: 'id',
				},
				{ text: 'Libellé', value: 'label' },
				{ text: 'Actions', value: 'actions', sortable: false },
			],
			items: [
			{
				id: 1,
				label: 'Loyer'
			},
			{
				id: 2,
				label: 'Salaire'
			},
			{
				id: 3,
				label: 'Affranchissement'
			}
        ],
		defaultItem :  {
			id: 0,
			label: ''
		},
      }
	},
	components: {
		TableManagement
	},
	methods: {
		onCreate(item) {
			console.log(JSON.stringify(item))
		},
		onUpdate(item) {
			console.log(JSON.stringify(item))
		}
	}
}
</script>

<style>

</style>