<template> 
<div>

    <!-- Stack the columns on mobile by making one full-width and the other half-width -->
    <v-row>
		<v-col cols="12" md="12">
			<v-card class="pa-2" outlined tile elevation="5" >
				<v-card-title>Clients</v-card-title>
			</v-card>
		</v-col>
    </v-row>

	<v-row>
		<v-col>
			<v-card  class="pa-2" outlined tile elevation="5">
				<v-data-table :headers="headers" :items="clients" :loading="loading" :server-items-length="total" :options.sync="options">
					<!-- <template v-slot:item.name="props">
						<v-edit-dialog :return-value.sync="props.item.name" @save="save" @cancel="cancel" @open="open" @close="close">
						{{ props.item.name }}
						<template v-slot:input>
							<v-text-field
							v-model="props.item.name"
							:rules="[max25chars]"
							label="Edit"
							single-line
							counter
							></v-text-field>
						</template>
						</v-edit-dialog>
					</template>
					<template v-slot:item.iron="props">
						<v-edit-dialog
						:return-value.sync="props.item.iron"
						large
						persistent
						@save="save"
						@cancel="cancel"
						@open="open"
						@close="close"
						>
						<div>{{ props.item.iron }}</div>
						<template v-slot:input>
							<div class="mt-4 text-h6">
							Update Iron
							</div>
							<v-text-field
							v-model="props.item.iron"
							:rules="[max25chars]"
							label="Edit"
							single-line
							counter
							autofocus
							></v-text-field>
						</template>
						</v-edit-dialog>
					</template> -->
				</v-data-table>

				<v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
					{{ snackText }}
					<template v-slot:action="{ attrs }">
						<v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
					</template>
				</v-snackbar>				
			</v-card>
		</v-col>
	</v-row>
  </div>
</template>


<script>
import OrganisationService from "../services/OrganisationService"

  export default {
    data () {
      return {
        snack: false,
        snackColor: '',
        snackText: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        pagination: {},
		total: 10000,
        loading: true,
        options: {},
        headers: [
			{
				text: 'Id',
				align: 'start',
				sortable: false,
				value: 'id_organisation',
			},
			{ text: 'Code', value: 'code_organisation_prospect' },
			{ text: 'Type', value: 'genre_activite' },
			{ text: 'Raison soc.', value: 'raison_sociale' },
			{ text: 'Nom', value: 'nom_contact' },
			{ text: 'Prenom', value: 'prenom_contact' },
			{ text: 'CP.', value: 'code_postal' },
			{ text: 'Ville', value: 'ville' },
			{ text: 'Pays', value: 'libelle_pays' },
			{ text: 'Email', value: 'email' },
        ],
        clients: []
      }
    },
	mounted() {	
		this.load(1, 10)
	},
	watch: {
      options: {
        handler () {
          console.log(this.options)
			this.load(this.options.page, this.options.itemsPerPage)
          this.$emit("onOptionsChanged", this.options)
        },
        deep: true,
      },
    },
    methods: {
		load(page_number, page_size) {
			this.loading = true
			OrganisationService.Customers(page_number,page_size,this.options.sortBy,this.options.sortDesc).then(result => {
				this.loading = false
				if(result.data.statut == 'OK')
					this.clients = result.data.list_clients
			})
		},
      save () {
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
      },
      cancel () {
        this.snack = true
        this.snackColor = 'error'
        this.snackText = 'Canceled'
      },
      open () {
        this.snack = true
        this.snackColor = 'info'
        this.snackText = 'Dialog opened'
      },
      close () {
        console.log('Dialog closed')
      },
    },
  }
</script>

<style>

</style>