import Api from '@/services/Api'
import authHeader from './Auth-header';

function Customers (page_number, page_size, sort_by, sort_desc) {

  let current_page = (page_number - 1) * page_size
  return Api().get(`/organisation/clientsEnCours/${current_page}/${page_size}?sortBy=${sort_by}&order=${sort_desc}`, null, { headers: authHeader()})
  //return Promise.resolve({status : "OK", message_specifique:"", data:{id_fiche:1}})
}

export default {
  Customers
}