<template>
	<table-management tableName="Banques" :headers="headers" :items="items" sortby="calories" :defaultItem="defaultItem" @onUpdate="onUpdate" @onCreate="onCreate"></table-management>
</template>

<script>
import TableManagement from "../components/TableManagement.vue"
export default {
	name:"Banques",
	data () {
		return { 
			headers: [
				{
					text: 'Nom',
					align: 'start',
					sortable: false,
					value: 'nom',
				},
				{ text: 'Domiciliation', value: 'domiciliation' },
				{ text: 'BIC', value: 'bic' },
				{ text: 'IBAN', value: 'iban' },
				{ text: 'Actions', value: 'actions', sortable: false },
			],
			items: [
			{
				nom: 'LCL',
				domiciliation: '23 Rue Royale, 75008 Paris',
				bic: 'LCLXXX',
				iban: 'FR260022115544478'
			},
			{
				nom: 'SG',
				domiciliation: '145 Rue Montaigne, 75008 Paris',
				bic: 'SGGXXX',
				iban: 'FR452111000557701'
			},
         
        ],
		defaultItem :  {
			nom: '',
			domiciliation: '',
			bic: '',
			iban: ''
		},
      }
	},
	components: {
		TableManagement
	},
	methods: {
		onCreate(item) {
			console.log(JSON.stringify(item))
		},
		onUpdate(item) {
			console.log(JSON.stringify(item))
		}
	}
}
</script>

<style>

</style>